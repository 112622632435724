import { logError } from '@Utils/helpers';

const ErrorsTranslationsMap = {
  error_api_google_resource_is_not_secure: 'msg_error_resource_is_not_secure',
  error_api_twitter_invalid_credentials: 'msg_error_invalid_credentials',
  error_api_twitter_something_wrong: 'msg_error_something_wrong_with_twitter',
  error_api_youtube_something_wrong_with_url: 'msg_error_something_wrong_with_url',
  error_api_youtube_something_wrong_with_youtube: 'msg_error_something_wrong_with_youtube',
  error_api_youtube_could_not_find_video: 'msg_error_could_not_find_youtube_video',
  error_invalid_api_key: 'msg_error_invalid_api_key',
  error_api_rate_limited: 'msg_error_rate_limited',
  error_api_page_not_found: 'msg_error_page_not_found',
  error_channels_invalid_shows: 'msg_error_invalid_shows',
  error_channels_invalid_id: 'msg_error_channel_invalid_id',
  error_invalid_permission: 'msg_error_invalid_permission',
  error_entity_does_not_exist: 'msg_error_entity_does_not_exist',
  error_media_library_entity_does_not_exist: 'msg_error_entity_does_not_exist',
  error_media_library_dropbox_credentials_were_not_registered: 'msg_error_dropbox_credentials_were_not_registered',
  error_image_size_too_large: 'msg_error_image_size_too_large',
  error_wrong_image_format: 'msg_error_wrong_image_format',
  error_dropbox_cannot_reach: 'msg_error_cannot_reach_dropbox',
  error_organizations_invitation_key_already_used: 'msg_error_invitation_key_already_used',
  error_organizations_already_belong_to_the_organization: 'msg_error_you_already_belong_to_the_organization',
  error_organizations_no_active_organization: 'msg_error_no_active_organization',
  error_organizations_user_already_belongs_to_the_organization: 'msg_error_user_already_belongs_to_the_organization',
  error_organizations_invitation_key_invalid: 'msg_error_invitation_key_invalid',
  error_organizations_user_not_found: 'msg_error_user_not_found',
  error_organizations_cannot_remove_owner: 'msg_error_cannot_remove_owner',
  error_organizations_not_found: 'msg_error_organization_not_found',
  error_organizations_owners_cannot_leave_organizations: 'msg_error_owners_cannot_leave_organizations',
  error_organizations_cannot_change_owner_role: 'msg_error_cannot_change_owner_role',
  error_organizations_such_credential_is_already_registered: 'msg_error_such_credential_is_already_registered',
  error_organizations_such_credential_is_not_registered: 'msg_error_such_credential_is_not_registered',
  error_enter_a_valid_email_address: 'msg_error_enter_a_valid_email_address',
  error_screens_not_found_or_already_registered: 'msg_error_screen_not_found_or_already_registered',
  error_screens_channel_not_found: 'msg_error_channel_not_found',
  error_screens_not_found: 'msg_error_screen_not_found',
  error_screens_blocked: 'msg_error_screen_blocked',
  error_media_library_image_does_not_exist: 'msg_error_media_library_image_does_not_exist',
  error_shows_invalid_channels: 'msg_error_invalid_channels',
  error_shows_invalid_id: 'msg_error_show_invalid_id',
  error_shows_invalid_show: 'msg_error_invalid_show',
  error_shows_invalid_template: 'msg_error_invalid_show_template',
  error_shows_invalid_data_with_schema: 'msg_error_invalid_data_with_schema',
  error_shows_slide_invalid_id: 'msg_error_slide_invalid_id',
  error_shows_all_slides_must_be_provided: 'msg_error_all_slides_must_be_provided',
  error_shows_no_duplicate_slides_for_reorder: 'msg_error_no_duplicate_slides_for_reorder',
  error_show_matching_query_does_not_exist: 'msg_error_show_matching_query_does_not_exist',
  error_showslide_matching_query_does_not_exist: 'msg_error_showslide_matching_query_does_not_exist',
  error_users_activation_key_invalid: 'msg_error_activation_key_invalid',
  error_users_already_verified: 'msg_error_already_verified',
  error_users_password_key_invalid: 'msg_error_password_key_invalid',
  error_users_password_key_expired: 'msg_error_password_key_expired',
  error_users_invalid_email: 'msg_error_invalid_email',
  error_users_name_cannot_be_blank: 'msg_error_name_cannot_be_blank',
  error_users_duplicate_email_key: 'msg_error_duplicate_email_key',
  error_users_cannot_save_user_avatar: 'msg_error_cannot_save_user_avatar',
  error_users_conditions_not_accepted: 'msg_error_conditions_not_accepted',
  error_users_does_not_exist: 'msg_error_user_does_not_exist',
  error_users_token_expired: 'msg_error_token_expired',
  error_users_invalid_credentials: 'msg_error_invalid_credentials',
  error_users_organization_group_entity_does_not_exist: 'msg_error_organization_group_entity_does_not_exist',
  error_this_password_is_too_common: 'msg_error_this_password_is_too_common',
  error_this_password_is_entirely_numeric: 'msg_error_this_password_is_entirely_numeric',
  error_password_only_contains_letters: 'msg_error_password_only_contains_letters',
  error_value_too_long: 'msg_error_value_too_long',
  error_invalid_active_organization: 'msg_error_invalid_active_organization',
  error_invalid_global_id: 'msg_error_invalid_global_id',
  error_users_jwt_token_expire_or_invalid: 'msg_error_jwt_token_expire_or_invalid',
  error_token_expired: 'msg_error_token_expired',
} as const;

export const getErrorCodeTranslationKey = (errorCode: string) => {
  if (!(errorCode in ErrorsTranslationsMap)) {
    logError(`Missing translation for error code: ${errorCode}`);
    return 'msg_error_unexpected';
  }

  return ErrorsTranslationsMap[errorCode as keyof typeof ErrorsTranslationsMap];
};
